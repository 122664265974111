/* book form  */
.book-form-parent {
  margin: 20px auto;
  width: 100%;
  max-width: 320px;
}

.book-form {
  width: 100%;
  text-align: center;
}

.book-form-inputs {
  text-align: left !important;
  all: unset;
  font-family: inherit;
  background-color: rgb(218, 218, 218);
  width: 90%;
  max-width: 100%;
  padding: 8px 0 8px 10px;
  margin: 0.5rem auto;
  border-radius: 5px;
}

.book-form-inputs:focus {
  outline: 2px solid rgb(177, 161, 23);
}

.book-form-submit-parent {
  max-width: 100%;
  margin: 0.5rem auto 1rem auto;
}

.book-form-submit {
  all: unset;
  text-align: center;
  padding: 5px;
  width: 90%;
  background-color: rgb(124, 175, 3);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}

.book-form-submit:hover {
  background-color: rgb(141, 201, 2);
}

.book-form-submit:hover .fa-square-plus {
  transform: rotate(90deg);
}

.fa-square-plus {
  transition: all 0.3s;
  margin: 0 5px;
}

/* book list  */
.book-list-parent {
  width: 100%;
  margin: 2rem auto;
  padding: 0 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 450px;
  margin: auto;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(245, 245, 220, 0.4);
}

.book-table-head {
  padding: 8px;
  text-align: left;
  background-color: rgb(253, 217, 174);
  font-weight: 500;
}

.book-id-head {
  width: 5%;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.book-id-value {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.book-name-head:nth-child(1) {
  width: 40%;
}

.book-author-head:nth-child(1) {
  width: 40%;
}

.book-publish-head:nth-child(3) {
  width: 15%;
}

td {
  padding: 5px 10px;
}

tbody tr {
  transition: all 0.2s;
  cursor: pointer;
}

tbody tr:nth-child(even) {
  background-color: rgba(245, 245, 220, 1);
}

tbody tr:hover {
  background-color: rgb(250, 105, 105);
  color: #fff;
}

tfoot {
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.form-error {
  margin: 1rem auto;
  color: red;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .book-list-parent {
    width: 60%;
  }

  table {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .book-form-parent {
    width: 40%;
  }
}

@media screen and (min-width: 768px) {
  main {
    display: flex;

    justify-content: center;
    max-width: 992px;
    margin: auto;
  }
}
