header {
  width: 100%;
  text-align: center;
  font-size: 0.9em;
  margin: 2rem auto 0.5rem auto;
}

header h1 span {
  color: #00a2cf;
  font-size: 1.1em;
}

.fa-book {
  font-size: 1em;
  margin: 0 5px;
  color: #00a2cf;
}

@media screen and (min-width:578px) {
  header h1 {
    font-size: 2.8em;
  }
}
